.mynav{
    width: 100%;
    white-space: nowrap;
}
.content{
    margin-left: auto;
    margin-right: auto;
    width: 90em;
}
.data{
    display: grid;
    grid-template-columns: repeat(8, 1fr);
}
.mheader{
    background-color: darkgreen;
}

.mbody{
    background-color: lightslategray;
}
.shaking-img{
    animation: shake-img infinite 0.5s linear alternate;
    position: relative;
    left: 30%;
    margin-right: -50%;
    transform: translate(-50%, 0%) 
}
@keyframes shake-img {
    from{
        transform:rotate(-5deg);
    }
    to{
        transform:rotate(5deg);
    }
}
.fancy-img{
    animation: fancy1 infinite 3s linear alternate;
    position: relative;
    left: 30%;
    margin-right: -50%;
    transform: translate(-50%, 0%) 
}
@keyframes fancy1 {
    0%{background-color:red; transform:rotate(0deg); }
    80%{background-color:yellow; transform:rotate(15deg);}
    90%{background-color:blue; transform:rotate(-15deg);transform:translateX(70px);} 
    100%{background-color:green; transform:rotate(-180deg); transform:translateX(-70px);}

}

@keyframes fancy2 {
    from   {background-color:red; transform:rotate(0deg); }
    to  {background-color:yellow; transform:rotate(15deg);}
}
@keyframes fancy3 {
    from   {background-color:red; transform:rotate(0deg); }
    to  {background-color:yellow; transform:rotate(15deg);}
    50%  {background-color:blue; transform:rotate(-15deg);transform:translateX(70px); }
    75%  {background-color:green; transform:rotate(-180deg); transform:translateX(-70px);}
}
@keyframes fancy4 {
    from   {background-color:red; transform:rotate(0deg); }
    to  {background-color:yellow; transform:rotate(15deg);}
    50%  {background-color:blue; transform:rotate(-15deg);transform:translateX(70px); }
    75%  {background-color:green; transform:rotate(-180deg); transform:translateX(-70px);}
}



.div1{
    margin: 5px;
    width: 50px;
    height: 50px;
    animation: pulse 5s infinite;
    position: relative;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, 0%) 
}

@keyframes pulse{
    0%{
        background-color: #001F3F
    }
    100%{
        background-color: #FF4236
    }

}
.div2{
    height: 50px;
    width: 50px;

    background-color: red;
    animation-name: stretch;
    animation-duration: 1.5s; 
    animation-timing-function: ease-out; 
    animation-delay: 0;
    animation-direction: alternate;
    animation-iteration-count: infinite;
    animation-fill-mode: none;
    animation-play-state: running;
    position: relative;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, 0%) 11
}
@keyframes stretch {
    0% {
      transform: scale(.3);
      background-color: red;
      border-radius: 100%;
    }
    50% {
      background-color: orange;
    }
    100% {
      transform: scale(1.5);
      background-color: yellow;
    }
  }

.div3{
    height: 100px;
    width: 100px;
    position: relative;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, 0%);
    background-color: red;
    border-radius: 100%;
    animation:
          pulse 3s ease infinite alternate,
          nudge 5s linear infinite alternate;
     
    }
    @keyframes pulse{
        0%, 100%{
            background-color: red;
        }
        50%{
            background-color: orange;
        }
    }
    @keyframes nudge{
        0%, 100%{
            transform: translate(0,0);
        }
        50%{
            transform: translate(150px, 0);
        }
        80%{
            transform: translate(-150px, 0);
        }
     
    }
.myspan {
    position: relative;
    display: block;
    width: 110px;
    height: 110px;
    overflow: hidden;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, 0%) 
}
.myspan-stop{
    position: relative;
    display: block;
    width: 110px;
    height: 110px;
    overflow: hidden;
}
.myspan .mydiv {
    margin-top: -17%;
    height: 34%; 
    width: 2%;
    top: 30%;
    border-radius: 20px;
    position: absolute;
}


.myspan .mydiv:nth-of-type(1)  { animation: wave 17s   0.000s linear infinite; }
.myspan .mydiv:nth-of-type(2)  { animation: wave 17s -16.227s linear infinite; }
.myspan .mydiv:nth-of-type(3)  { animation: wave 17s -15.455s linear infinite; }
.myspan .mydiv:nth-of-type(4)  { animation: wave 17s -14.682s linear infinite; }
.myspan .mydiv:nth-of-type(5)  { animation: wave 17s -13.909s linear infinite; }
.myspan .mydiv:nth-of-type(6)  { animation: wave 17s -13.136s linear infinite; }
.myspan .mydiv:nth-of-type(7)  { animation: wave 17s -12.364s linear infinite; }
.myspan .mydiv:nth-of-type(8)  { animation: wave 17s -11.591s linear infinite; }
.myspan .mydiv:nth-of-type(9)  { animation: wave 17s -10.818s linear infinite; }
.myspan .mydiv:nth-of-type(10) { animation: wave 17s -10.045s linear infinite; }
.myspan .mydiv:nth-of-type(11) { animation: wave 17s  -9.273s linear infinite; }
.myspan .mydiv:nth-of-type(12) { animation: wave 17s  -8.500s linear infinite; }
.myspan .mydiv:nth-of-type(13) { animation: wave 17s  -7.727s linear infinite; }
.myspan .mydiv:nth-of-type(14) { animation: wave 17s  -6.955s linear infinite; }
.myspan .mydiv:nth-of-type(15) { animation: wave 17s  -6.182s linear infinite; }
.myspan .mydiv:nth-of-type(16) { animation: wave 17s  -5.409s linear infinite; }
.myspan .mydiv:nth-of-type(17) { animation: wave 17s  -4.636s linear infinite; }
.myspan .mydiv:nth-of-type(18) { animation: wave 17s  -3.864s linear infinite; }
.myspan .mydiv:nth-of-type(19) { animation: wave 17s  -3.091s linear infinite; }
.myspan .mydiv:nth-of-type(20) { animation: wave 17s  -2.318s linear infinite; }
.myspan .mydiv:nth-of-type(21) { animation: wave 17s  -1.545s linear infinite; }
.myspan .mydiv:nth-of-type(22) { animation: wave 17s  -0.773s linear infinite; }

@keyframes wave {
    0%   { left:-2%; background: #3B44D1; }
    5%   { background: #9337FE; }
    10%  { height:10%; margin-top: -5%; background: #C532FC; }
    15%  { background: #F639F8; }
    20%  { height:34%; margin-top:-17%; background: #F236C8; }
    25%  { background: #FF2F8D; }
    30%  { height:10%; margin-top: -5%; background: #EE3860; }
    35%  { background: #DC5245; }
    40%  { height:34%; margin-top:-17%; background: #F38643; }
    45%  { background: #F8B435; }
    50%  { height:10%; margin-top: -5%; background: #FAF444; }
    55%  { background: #E0FF3B; }
    60%  { height:34%; margin-top:-17%; background: #E1FF3C; }
    65%  { background: #46F443; }
    70%  { height:10%; margin-top: -5%; background: #54E67B; }
    75%  { background: #4DF3A9; }
    80%  { height:34%; margin-top:-17%; background: #3AF9DA; }
    85%  { background: #36EBF4; }
    90%  { height:10%; margin-top: -5%; background: #3DB3F3; }
    95%  { background: #3C82F1; }
    100% { height:34%; margin-top:-17%; left:100%; background: #5B38EE; }
  }
  .player{
      padding: 80px ;
      border-style: solid;
      width: fit-content;
      margin: auto;
      background-color: rgb(33, 114, 128);
  }
  .centered{
    position: relative;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, 0%);
    color: black;
    font-size: large;
    font-weight: bold;
    padding-bottom: 50px;
  }